import HomeSlide from "./HomeSlide";
import Intro from "./Intro";
import Motto from "./Motto";
import Opportunities from "./Opportunities";
import UpcomingTournamets from "./UpcomingTournaments";
import LeaderBoard from "./LeaderBoard";


const Home = () => {
  return (
    <>
      <HomeSlide />
      <Intro />
      <Motto />
      <Opportunities />
      <UpcomingTournamets />
      <LeaderBoard />
    </>
  );
};
export default Home;
