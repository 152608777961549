import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header>
        <div className="c">
          <div className="container">
            <div className="top">
              <p>Follow Us On:</p>
              <ul>
                <li>
                  <a href>
                    <i className="fa-brands fa-twitch" />
                  </a>
                </li>
                <li>
                  <a href>
                    <i className="fa-brands fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href>
                    <i className="fa-brands fa-discord" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="bottom">
              <div className="lefts">
                <h1>CODBD</h1>
              </div>
              <ul>
                <li>
                  <NavLink
                    className={(navData) => (navData.isActive ? `nav_act` : ``)}
                    to={"/"}
                  >
                    HOME
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={(navData) => (navData.isActive ? `nav_act` : ``)}
                    to={"/rules"}
                  >
                    RULES
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={(navData) => (navData.isActive ? `nav_act` : ``)}
                    to={"/sponsors"}
                  >
                    SPONSORS
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={(navData) => (navData.isActive ? `nav_act` : ``)}
                    to={"/tournaments"}
                  >
                    TOURNAMENTS
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={(navData) => (navData.isActive ? `nav_act` : ``)}
                    to={"/ranking"}
                  >
                    RANKING
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={(navData) => (navData.isActive ? `nav_act` : ``)}
                    to={"/about_us"}
                  >
                    ABOUT US
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="des">
          
        </div>
      </header>
    </>
  );
};
export default Header;
