const HomeSlide = () => {
  return (
    <>
      <section>
        <div className="video_slide">
          <div className="contents">
            <h5>
              Welcome to <span className="color_primary">callofdutybd.com</span>
            </h5>
            <h2>
              Call of Duty <span className="color_primary">COMMUNITY</span>{" "}
              <br /> IN <span className="color_primary">Bangladesh</span>
            </h2>
          </div>
          <iframe
            itemID="video_if"
            className="elementor-background-video-embed"
            frameBorder={0}
            allowFullScreen={1}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="YouTube video player"
            width={640}
            height={360}
            src="https://www.youtube.com/embed/ljLBEbAKkvE?controls=0&rel=0&playsinline=1&enablejsapi=1&widgetid=1&autoplay=1&mute=1&loop=1&fullscreen=1"
            id="widget2"
            style={{
              height: "600px",
              width: "100%",
              maxWidth: "100%",
              maxHeight: "949px",
            }}
            __idm_id__={1384449}
          />
        </div>
      </section>
    </>
  );
};
export default HomeSlide;
