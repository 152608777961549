const Intro = () => {
  return (
    <>
      <section className="intro_text bg_pic_1">
        <div className="container">
          <div className="intro_text_content ">
            <div className="text_contents">
              <h4>
                Welcome to{" "}
                <span className="color_primary">CALLOFDUTYBD.COM</span>,<br />
                the largest and most active Call of Duty Community of <br />
                <span className="color_primary">BANGLADESH</span>
              </h4>
              <p>
                callofdutybd.com is associated with the Bangladeshi Call of Duty
                community and other correlated games of the Call of Duty
                franchise. Our objective is to nurture and motivate the
                community consisting of almost 3000 players while maintaining a
                friendly and professional attitude among all members.
              </p>
            </div>
            <img src="../img/d.png" alt="" srcSet />
          </div>
        </div>
      </section>
    </>
  );
};
export default Intro;
