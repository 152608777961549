import PageBG from "../components/PageBG";
import LeaderBoard from "../components/LeaderBoard";
const PageRanks = () => {
  return (
    <>
      <PageBG title={"rankings"} />
      <LeaderBoard />
    </>
  );
};
export default PageRanks;
