import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer className="bg_pic_5 footer">
        <div className="container">
          <div className="top_contents">
            <div className="top">
              <h1>CALL OF DUTY BD</h1>
              <div className="lefts">
                <ul>
                  <li>
                    <Link to={"/sponsors"}>
                      <i className="fa-solid fa-circle-right" /> Sponsors
                    </Link>
                  </li>
                  <li>
                    <Link to={"/about_us"}>
                      <i className="fa-solid fa-circle-right" /> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to={"/news"}>
                      <i className="fa-solid fa-circle-right" /> News
                    </Link>
                  </li>
                  <li>
                    <Link to={"/streamers"}>
                      <i className="fa-solid fa-circle-right" /> Streamers
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bottom">
              <ul>
                <li>
                  <i className="fa-solid fa-envelope" /> info@callofdutybd.com
                </li>
                <li>
                  <i className="fa-solid fa-location-pin" /> BANGLADESH
                </li>
              </ul>
            </div>
          </div>
          <div className="bottom_contents">
            <p>
              callofdutybd.com © 2022 | All rights reserved by{" "}
              <a href="callofdutybd.com">CALLOFDUTYBD.COM</a>
            </p>
            <div className="socials">
              <ul>
                <li>
                  <a href>
                    <i className="fa-brands fa-twitch" />
                  </a>
                </li>
                <li>
                  <a href>
                    <i className="fa-brands fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href>
                    <i className="fa-brands fa-discord" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
