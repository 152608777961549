import PageBG from "../components/PageBG";
import UpcomingTournamets from "../components/UpcomingTournaments";
const PageTournaments = () => {
  return (
    <>
      <PageBG title={"COD BD TOURNAMENTS"} />
      <UpcomingTournamets />
    </>
  );
};
export default PageTournaments;
