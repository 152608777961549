const LeaderBoard = () => {
  return (
    <>
      <section className="player_rank bg_pic_3">
        <div className="container">
          <div className="player_rank_table">
            <h1>Player Leader Board</h1>
            <table className="table table-striped wh">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">PLAYER</th>
                  <th scope="col">USERNAME</th>
                  <th scope="col">RANK</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td colSpan={2}>Larry the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};
export default LeaderBoard;
