const Motto = () => {
  return (
    <>
      <section className="bg_pic_2 moto_section">
        <div className="container">
          <div className="moto_text_contents">
            <h4>
              Welcome to <span className="color_primary">CALLOFDUTYBD.COM</span>
              ,<br />
              the largest and most active Call of Duty Community of <br />
              <span className="color_primary">BANGLADESH</span>
            </h4>
          </div>
        </div>
      </section>
    </>
  );
};
export default Motto;
