import PageBG from "./PageBG";
const Rules = () => {
  return (
    <>
        <PageBG title={"RULES"} />
      <section className="rules">

        <div className="rules_d">
          <div className="container">
            <div className="general_rules">
              <h1>General Rules</h1>
              <ul>
                <li>Players must enter their Activision ID</li>
                <li>
                  Players must not change this Activision ID after registering
                </li>
                <li>
                  Only applicants of Bangladesh can participate in
                  callofdutybd.com tournament – any non-resident players will
                  automatically be disqualified.
                </li>
                <li>
                  Sri Lankan applicants living abroad can participate in
                  tournaments without any restrictions, however all matches will
                  be hosted in Asian region.
                </li>
                <li>
                  Changes to General Rules: Tournament dates are subject to
                  change. Each participant is assumed to be aware of all the
                  rules and checks them periodically
                </li>
                <li>
                  Insufficient knowledge of the rules can not be used as an
                  excuse for breaking the rules.
                </li>
                <li>
                  Participation in any Tournament will constitute acceptance of
                  the changed rules, instructions and guidance.
                </li>
                <li>
                  Acceptance: Each participant must agree to these rules in
                  order to participate in the tournament.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Rules;
