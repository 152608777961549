const Opportunities = () => {
  return (
    <>
      <section className="opportunities bg_pic_3">
        <div className="container">
          <div className="opp_text_contents">
            <h2>
              This platform thrives to give{" "}
              <span className="color_primary">Bangladeshi</span> <br /> Call of
              Duty players the{" "}
              <span className="color_primary">Opportunity</span>
            </h2>
            <ul>
              <li>To meet new Call of Duty players and team up.</li>
              <li>Participate in grand scale COD events/tournaments.</li>
              <li>
                Discuss the game, share information such as tips and tactics.
              </li>
              <li>Share COD related media content.</li>
              <li>
                To check the ranks of Bangladeshi COD players, teams and clans.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
export default Opportunities;
