import PageBG from "./PageBG";
const Sponsors = () => {
  return (
    <>
      <PageBG title={"sponsors"} />
      <section className="rules">
        <div className="rules_d">
          <div className="container">
            <div className="general_rules">
              <h1 className="color_primary">THE PROSPECTS</h1>
              <br /> 3000 plus members in the community with an average growth
              rate of the player base by 10% per month that includes gamers from
              all platforms (PC, PS and Xbox etc). • Over 100 streamers
              streaming COD reaching a fan base of almost 10000 plus gamers. •
              Around 30 plus different teams/clans/regiments Playing COD in
              Bangladesh. • As of current date, untapped market for investors.
              <h1 className="color_primary">BENEFITS</h1>
              <br /> 1. Increase publicity Through our website, social media
              platforms and streaming outlets, we provide a cost-effective means
              to advertise your company and the products provided by you, to a
              hard to reach, niche market. This is exasperated by the seasons in
              which we plan to initiate them, especially during the holiday
              season as this will be a time period where both gamers and
              non-gamers have free time, especially to view online content.
              Henceforth the probability of viewing your brand, product, website
              (online footprint) and the products you provide would be much
              higher. <br /> <br />
              2. Esports contact We don’t only provide a marketing avenue for
              you, but also serve as a contact in the esports scene which can be
              invaluable for companies interested in accessing such as vast yet
              hard to reach sector of the market. In addition to the prestige of
              being associated with a platform that includes many teams and the
              streamers within a competitive sporting industry, we also provide
              advice such as recommendations for how best to use the largely
              untapped industry and additional contacts within it. <br /> <br />
              3. Potential In addition to the aforementioned points, the
              contemporary world is moving heavily towards digitization. Gaming
              related trends are growing at an exponential rate since a lot of
              individuals are based at home due to the Covid-19 scenario.
              Henceforth the future prospects in regards to the above also grow
              in a limitless manner.
              <h1 className="color_primary">HOW WE DO IT</h1>
              <br /> <br /> Our website, social media platforms and the
              streamers working in a cohesive manner attracts attention of a
              wide variety of gamers from all gaming platforms (PC, Xbox and
              PlayStation) in Sri Lanka. This is due to our regular posts,
              messages and interactions with the community and also hosting
              custom events and tournaments in behalf of the community which
              makes it one of the fastest growing social media presences under
              the Call of Duty category in Bangladesh. For our sponsors, we
              offer the placement of logos on the forefront of the website and
              tournament posters with superior positioning that demands
              attention. Your brand will have the privilege of being the first
              brand to appear under the Call of Duty COD scene in Sri Lanka.{" "}
              <br /> <br /> With website and social media related online
              promotions there will be more reach to your brand in the
              Bangladeshi online sphere, especially during these hectic Covid-19
              times where most of the people are at home. <br /> <br /> We are
              flexible with how we deal with sponsors. Henceforth there is no
              set plan for how our services are used. Rather we encourage
              discussion and negotiation with interested sponsors in order to
              construct the most effective strategy for different levels of
              support.
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Sponsors;
