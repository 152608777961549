import { Routes, Route, BrowserRouter } from "react-router-dom";

import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Rules from "./components/Rules";
import Sponsors from "./components/Sponsors";
import AboutUs from "./components/AboutUs";
import PageRanks from "./pages/PageRanks";
import PageTournaments from "./pages/PageTournaments";
function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/ranking" element={<PageRanks />} />
          <Route path="/tournaments" element={<PageTournaments />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
