const UpcomingTournamets = () => {
  return (
    <>
      <section className="upcomming_tournaments bg_pic_4">
        <div className="container">
          <h1>upcomming tournaments</h1>
          <div className="tour_blocks">
            <div className="tour_block">
              <div className="img">
                <img src="../img//10.jpg" alt="" srcSet />
              </div>
              <div className="tour_content">
                <h1>2v2 Tournamnet</h1>
                <p>Register Now</p>
                <button className="button_2">VIEW TOURNAMENT</button>
              </div>
            </div>
            <div className="tour_block">
              <div className="img">
                <img src="../img//10.jpg" alt="" srcSet />
              </div>
              <div className="tour_content">
                <h1>2v2 Tournamnet</h1>
                <p>Register Now</p>
                <button className="button_2">VIEW TOURNAMENT</button>
              </div>
            </div>
            <div className="tour_block">
              <div className="img">
                <img src="../img//10.jpg" alt="" srcSet />
              </div>
              <div className="tour_content">
                <h1>2v2 Tournamnet</h1>
                <p>Register Now</p>
                <button className="button_2">VIEW TOURNAMENT</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default UpcomingTournamets