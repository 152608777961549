const PageBG = ({ title, bg }) => {
  return (
    <>
      <div className={`clip img_intro rel ${bg ? bg : "sec_img_str"}`}>
        <div className="contents">
          <h1>
            <br />
            <span className="color_primary"> {title.toUpperCase()} </span>
          </h1>
        </div>
      </div>
    </>
  );
};
export default PageBG;
