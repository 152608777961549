import { useEffect } from "react";
import PageBG from "./PageBG";
const AboutUs = () => {
  useEffect(() => {
    let vision_text =
      "Our vision is to support, nurture and enhance the skills and the numbers of the Sri Lankan Warzone player base with the philosophy that 'to create a better gaming community you need to provide the right environment for them to grow, experiment and expand'. We strongly believe that the aforementioned solidifies sustainable growth and strong connections among all players, teams and clans, concordantly helping them reach better standards along their CALL OF DUTY journey.";
    let mission_text =
      "Our mission is to assist Sri Lankan players reach international standards and help them compete in the CALL OF DUTY global arena.";
    const div_text = document.getElementById("vision");
    const vision_btn = document.getElementById("vision_btn");
    const mission_btn = document.getElementById("mission_btn");

    vision_btn.addEventListener("click", vision);
    mission_btn.addEventListener("click", mission);
    function vision() {
      vision_btn.classList.add("m_act");
      mission_btn.classList.remove("m_act");
      div_text.innerText = vision_text;
    }
    function mission() {
      vision_btn.classList.remove("m_act");
      mission_btn.classList.add("m_act");
      div_text.innerText = mission_text;
    }
  }, []);
  return (
    <>
        <PageBG title={"ABOUT US"} />
      <section className="aboutus">
        <div className="rules_d">
          <div className="container">
            <p className="p_50">
              <span className="color_primary">callofdutybd.com</span> is aligned
              with the Bangladeshi Call of Duty community and other games of the
              cod franchise. Our objectives are correlated with nurturing and
              supporting the newly built community of over 5000 players whilst
              assisting in its growth to a more diverse and professional
              environment. With the current Bangladeshi network of COD players
              growing at a stable rate we decided to establish the first
              Bangladeshi website to boost the current reach and create more
              awareness along with promoting the competitive nature among the
              current Call of Duty players of Bangladesh.
            </p>
            <div className="mv">
              <div className="sm_div">
                <button onclick="vision" className="m_act" id="vision_btn">
                  OUR VISION
                </button>
                <button onclick="mission" id="mission_btn">
                  OUR MISSION
                </button>
              </div>
              <div id="vision">
                Our vision is to support, nurture and enhance the skills and the
                numbers of the Sri Lankan Warzone player base with the
                philosophy that 'to create a better gaming community you need to
                provide the right environment for them to grow, experiment and
                expand'. We strongly believe that the aforementioned solidifies
                sustainable growth and strong connections among all players,
                teams and clans, concordantly helping them reach better
                standards along their CALL OF DUTY journey.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AboutUs;
